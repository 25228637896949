<template>
  <div id="portfolio-project">
    <div class="portfolio-project-back">
      <BackButton :to="{name: 'portfolio'}"/>
    </div>
    <div class="portfolio-project-container">
      <div class="project-description">
        <span class="title">{{ title }}</span>
        <span class="subtitle">{{ subtitle }}</span>
        <p class="description"><span v-html="description"></span></p>
      </div>
      <div class="project-pictures">
        <div v-for="picture in pictures" class="picture" @click="openViewer(picture.path)">
          <img :src="getImagePath(picture.path)" alt="Picture">
        </div>
      </div>
    </div>
    <div id="picture-viewer" @click="closeViewer">
      <span class="cross item" @click="closeViewer"><i class="fa fa-times"></i></span>
      <!--      <span class="left item"><i class="fa fa-chevron-left"></i></span>-->
      <!--      <span class="right item"><i class="fa fa-chevron-right"></i></span>-->
      <img src="#" alt="Picture">
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton";
import projects from "@/http/projects";

export default {
  name: "PortfolioProject",
  components: {BackButton},
  data() {
    return {
      pictureViewer: null,
      imgPictureViewer: null,
      title: "",
      subtitle: "",
      description: "",
      pictures: []
    }
  },
  methods: {
    openViewer(picture) {
      this.imgPictureViewer.src = this.getImagePath(picture);
      this.pictureViewer.classList.add("open");
    },
    closeViewer() {
      this.pictureViewer.classList.remove("open");
    },
    getImagePath(picture) {
      return (process.env.NODE_ENV === "production" ? "/" : "http://localhost:8000/") + picture;
    }
  },
  mounted() {
    this.pictureViewer = document.querySelector("div#picture-viewer");
    this.imgPictureViewer = this.pictureViewer.querySelector("img");
    this.imgPictureViewer.onclick = e => {
      e.stopImmediatePropagation();
    };
  },
  created() {
    let project = projects.findOne(this.$route.params.project);
    project.then(value => {
      let data = value.data;
      ["title", "subtitle", "description", "pictures"].forEach(element => {
        this[element] = data[element];
      });
      this.description = this.description
          .replaceAll("\n", "<br/>")
          .replaceAll(/\b((https?:\/\/|(www)?\.)[^'">\s]+\.[^'">\s]+)(?=\s|$)(?!["<>])/g, "<a href='$1'>$1</a>");
    }).catch(reason => {
      console.log(reason);
    });
  }
}
</script>

<style scoped lang="less">
@media all and (max-width: 800px) {
  & div.portfolio-project-container {
    & div.project-description {
      width: 90% !important;
    }

    & div.project-pictures {
      & div.picture {
        width: calc(50% - 5px) !important;
        height: 0 !important;
        padding-bottom: calc(50% - 5px) !important;
      }
    }
  }

  & div#picture-viewer {
    & span.cross {
      right: 20px !important;
    }
  }
}

@media all and (max-width: 500px) {
  & div.portfolio-project-container {
    & div.project-pictures {
      & div.picture {
        width: calc(100% - 5px) !important;
        height: 0 !important;
        padding-bottom: calc(100% - 5px) !important;
      }
    }
  }
}

div#portfolio-project {
  max-width: 2000px;

  & div.portfolio-project-back {
    margin: 10px 20px;
  }

  & div.portfolio-project-container {
    width: 90%;
    margin: 0 auto;

    & div.project-description {
      display: flex;
      flex-direction: column;
      width: 60%;
      margin: 0 auto;

      & span.title {
        font-size: 2em;
      }

      & span.subtitle {
        font-size: .8em;
      }
    }

    & div.project-pictures {
      @size: 300px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      @media all and (min-width: 1300px) {
        @size: 360px;

        justify-content: center !important;

        & div.picture {
          width: @size !important;
          height: @size !important;
          margin: 5px !important;
        }
      }

      & div.picture {
        position: relative;
        width: @size;
        height: @size;
        overflow: hidden;
        margin: 5px auto;
        cursor: pointer;

        & img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  & div#picture-viewer {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(50, 50, 50, .7);

    &.open {
      display: block !important;
    }

    & span.item {
      position: absolute;
      color: white;
      font-size: 2.5em;
      cursor: pointer;
      z-index: 200;

      &.cross {
        top: 20px;
        right: 50px;
      }

      //&.left, &.right {
      //  top: 50%;
      //  transform: translateY(-50%);
      //}
      //
      //&.right {
      //  right: 5px;
      //  //transform: translate(-100%, -50%);
      //}
      //
      //&.left {
      //  left: 5px;
      //}
    }

    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      //max-width: calc(90% - 60px);
      max-width: 95%;
      //max-height: 90%;
      max-height: 95%;
      transform: translate(-50%, -50%);
      z-index: 199;
    }
  }
}
</style>
